<template>
  <div>
    <div class="vg_mb_16 flexV">
      <el-button v-show="modcForm.modc_type === 7" plain size="small" type="primary" @click="clickImport">导入材料</el-button>
    </div>
    <el-table ref="multiTable" :data="modcForm.modc_mtrb_list" :header-cell-style="headerCellStyle" border @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="48" align="center" :selectable="() => modcForm.modc_type === 9"></el-table-column>
      <el-table-column label="序号" width="60" align="center">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="产品类型" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'modc_mtrb_list.' + scope.$index + '.mtrb_ilk'" label-width="0">
            <el-select v-model="scope.row.mtrb_ilk" :disabled="modc_mtrb_list_disable_form.mtrb_ilk" filterable placeholder="请选择产品类型">
              <el-option
                v-for="item in [
                  { value: 0, label: '新款' },
                  // { value: 1, label: '修改' },
                  { value: 2, label: '翻单' }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="材料编号" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'modc_mtrb_list.' + scope.$index + '.mtrb_no'">
            <el-input
              v-model="scope.row.mtrb_no"
              :disabled="modc_mtrb_list_disable_form.mtrb_no"
              maxlength="20"
              show-word-limit
              placeholder="暂无辅料号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="客户订单号" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'modc_mtrb_list.' + scope.$index + '.scon_cust_no'">
            <el-input
              v-model="scope.row.scon_cust_no"
              :disabled="modc_mtrb_list_disable_form.scon_cust_no"
              maxlength="20"
              show-word-limit
              placeholder="暂无客户订单号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="客户货号" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'modc_mtrb_list.' + scope.$index + '.cust_catalog_number'">
            <el-input
              v-model="scope.row.cust_catalog_number"
              :disabled="modc_mtrb_list_disable_form.cust_catalog_number"
              maxlength="20"
              show-word-limit
              placeholder="暂无客户货号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="我司货号" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'modc_mtrb_list.' + scope.$index + '.prod_no'">
            <el-input
              v-model="scope.row.prod_no"
              :disabled="modc_mtrb_list_disable_form.prod_no"
              maxlength="20"
              show-word-limit
              placeholder="暂无我司货号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="中文品名" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'modc_mtrb_list.' + scope.$index + '.mtrb_name'">
            <el-input
              v-model="scope.row.mtrb_name"
              :disabled="modc_mtrb_list_disable_form.mtrb_name"
              maxlength="20"
              show-word-limit
              placeholder="暂无中文品名"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="材料规格" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'modc_mtrb_list.' + scope.$index + '.mtrb_spec'">
            <el-input v-model="scope.row.mtrb_spec" :disabled="modc_mtrb_list_disable_form.mtrb_spec" placeholder="暂无材料规格" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="数量单位" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'modc_mtrb_list.' + scope.$index + '.mtrb_unit'">
            <el-input
              v-model="scope.row.mtrb_unit"
              :disabled="modc_mtrb_list_disable_form.mtrb_unit"
              maxlength="20"
              show-word-limit
              placeholder="暂无数量单位"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="原合同数量" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'modc_mtrb_list.' + scope.$index + '.mtrb_num'">
            <el-input
              v-model="scope.row.mtrb_num"
              :disabled="modc_mtrb_list_disable_form.mtrb_num"
              maxlength="20"
              show-word-limit
              placeholder="暂无原合同数量"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="type56 + '合同数量'" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'modc_mtrb_list.' + scope.$index + '.modc_num'" :rule="[{ required: type2 }]" label-width="0">
            <el-input
              v-model="scope.row.modc_num"
              :disabled="modc_mtrb_list_disable_form.modc_num"
              @change="calcProdPrice(scope.$index)"
              @input="scope.row.modc_num < 1 ? (scope.row.modc_num = 1) : (scope.row.modc_num = keepNumber(scope.row.modc_num))"
              maxlength="11"
              show-word-limit
              placeholder="暂无"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="备品数量" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'modc_mtrb_list.' + scope.$index + '.mtrb_bnum'">
            <el-input
              v-model="scope.row.mtrb_bnum"
              :disabled="modc_mtrb_list_disable_form.mtrb_bnum"
              maxlength="20"
              show-word-limit
              placeholder="暂无备品数量"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="原合同单价" width="200">
        <template v-slot="scope">
          <el-form-item v-if="!isQCDepartment" :prop="'modc_mtrb_list.' + scope.$index + '.prod_mtrb_price'" label-width="0">
            <el-input
              v-model="scope.row.prod_mtrb_price"
              :disabled="modc_mtrb_list_disable_form.prod_mtrb_price"
              maxlength="20"
              show-word-limit
              placeholder="暂无原合同单价"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="type56 + '合同单价'" width="200">
        <template v-slot="scope">
          <el-form-item v-if="!isQCDepartment" :prop="'modc_mtrb_list.' + scope.$index + '.modc_mtrb_price'" :rule="[{ required: type2 }]" label-width="0">
            <el-input
              v-model="scope.row.modc_mtrb_price"
              :disabled="modc_mtrb_list_disable_form.modc_mtrb_price"
              @change="calcProdPrice(scope.$index)"
              @input="val => (scope.row.modc_mtrb_price = keep4Decimal(val))"
              maxlength="9"
              show-word-limit
              placeholder="暂无"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="原合同金额" width="200">
        <template v-slot="scope">
          <el-form-item v-if="!isQCDepartment" :prop="'modc_mtrb_list.' + scope.$index + '.modc_total'" label-width="0">
            <el-input
              v-model="scope.row.modc_total"
              :disabled="modc_mtrb_list_disable_form.modc_total"
              maxlength="30"
              show-word-limit
              placeholder="暂无金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="type56 + '合同金额'" width="200">
        <template v-slot="scope">
          <el-form-item v-if="!isQCDepartment" :prop="'modc_mtrb_list.' + scope.$index + '.fact_modc_total'" :rule="[{ required: type2 }]" label-width="0">
            <el-input
              v-model="scope.row.fact_modc_total"
              :disabled="modc_mtrb_list_disable_form.fact_modc_total"
              @blur="scope.row.fact_modc_total = calcPrice(scope.row.fact_modc_total, 4, 100000)"
              @input="scope.row.fact_modc_total = helper.keepTNum1(scope.row.fact_modc_total)"
              maxlength="10"
              show-word-limit
              placeholder="暂无"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="交货期" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'modc_mtrb_list.' + scope.$index + '.modr_mtrb_pedate'">
            <el-date-picker
              v-model="scope.row.modr_mtrb_pedate"
              :disabled="modc_mtrb_list_disable_form.modr_mtrb_pedate"
              value-format="timestamp"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="成品采购合同号" prop="podr_no" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'modc_mtrb_list.' + scope.$index + '.podr_no'" label-width="0">
            <el-select
              v-model="scope.row.podr_id"
              :disabled="modc_mtrb_list_disable_form.podr_id"
              clearable
              filterable
              placeholder="请选择"
              @change="
                val => {
                  let temp = scope.row.list.find(x => Number(x.value) === val);
                  scope.row.podr_no = temp.label;
                  scope.row.supp_bid = Number(temp.permValue);
                  scope.row.supp_babbr = temp.permLabel;
                }
              "
            >
              <el-option v-for="item in scope.row.list" :key="item.value" :label="item.label" :value="Number(item.value)"></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="收货工厂" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'modc_mtrb_list.' + scope.$index + '.supp_babbr'">
            <el-input
              v-model="scope.row.supp_babbr"
              :disabled="modc_mtrb_list_disable_form.supp_babbr"
              maxlength="30"
              show-word-limit
              placeholder="暂无成品工厂"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="dialogVisible" title="材料" width="70%">
      <MtrbImport ref="MtrbImport" :scon_id="modcForm.scon_id" :supp_id="modcForm.supp_id" @childChanel="childChanel" @childConfirm="childConfirm"></MtrbImport>
    </el-dialog>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js';
import { getNoCatch } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { keep2Decimal, keep4Decimal, keepNumber } from '@assets/js/regExUtil';
import MtrbImport from '@/views/SalesManagement/ModcManage/TabChild/Component/MtrbImport.vue';

export default {
  name: 'MtrbDetail',
  components: { MtrbImport },
  props: {
    modcForm: {
      type: Object,
      required: true
    },
    modc_mtrb_list_disable_form: {
      type: Object,
      required: true
    },
    isQCDepartment: {
      type: Boolean
    }
  },
  data() {
    return {
      multipleSelection: [],
      rateList: [],
      dialogVisible: false
    };
  },
  created() {
    this.initData();
  },
  computed: {
    type56() {
      return [3, 4].includes(this.modcForm.modc_type) ? '新厂' : this.modcForm.modc_type === 6 ? '库存' : '更改后';
    },
    type2() {
      return this.modcForm.modc_type === 2;
    }
  },
  methods: {
    keepNumber,
    keep4Decimal,
    headerCellStyle({ column }) {
      if (this.type2 && String(column.label).indexOf('更改后') !== -1) return { color: 'red' };
    },
    keep2Decimal,
    initData() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10024 }).then(({ data }) => {
        this.rateList = data.form.optn_cntt_list;
      });
    },
    // 选择
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit('SelectChnage', this.multipleSelection);
    },
    // 变化内容
    changeConent() {
      setTimeout(() => {
        this.$emit('changeConent');
      }, 200);
    },
    // 计算金额
    calcProdPrice(val) {
      let prodTgweight = new BigNumber(1);
      prodTgweight = prodTgweight
        .times(this.modcForm.modc_mtrb_list[val].modc_num ? this.modcForm.modc_mtrb_list[val].modc_num : 0)
        .times(this.modcForm.modc_mtrb_list[val].modc_mtrb_price ? this.modcForm.modc_mtrb_list[val].modc_mtrb_price : 0);
      prodTgweight = prodTgweight.toFixed(4);
      this.$set(this.modcForm.modc_mtrb_list[val], 'fact_modc_total', prodTgweight);
      this.rateList
        .filter(({ param1 }) => param1 === this.modcForm.modc_mtrb_list[val].mtrb_pctype)
        .forEach(item => {
          let num = Number(this.modcForm.modc_mtrb_list[val].modc_num);
          if (num >= Number(item.param3) && num < Number(item.param2)) {
            this.modcForm.modc_mtrb_list[val].mtrb_bnum = new BigNumber(num).times(item.param4).div(100).toFixed(0, 0);
          }
        });
      this.changeConent();
    },

    // // 计算价格保留四位
    calcPrice(num, posi = 0, str) {
      let num1 = 0;
      let fre = null;
      if (posi % 3 === 1) {
        fre = Math.pow(10, posi + 1);
      } else {
        fre = Math.pow(10, posi);
      }
      num1 = (num * fre) / fre;
      if (isNaN(num1)) {
        return '';
      } else {
        if (num1 >= str) {
          return str - 0.0001;
        } else {
          let value = num1;
          value = value.toString();
          if (value) {
            let ind = value.toString().indexOf('.');
            if (ind < 0) {
              return value + '.0000';
            } else {
              if (value.length - 1 - ind > 4) {
                return value.substr(0, ind + 5);
              } else if (value.length - 1 - ind < 4) {
                let index = 4 - (value.length - 1 - ind);
                for (let i = 0; i < index; i++) {
                  value = value + '0';
                }
                return value;
              } else {
                return value;
              }
            }
          }
        }
      }
    },
    // 计算价格保留2位
    calcPriceT(num, posi = 0, str) {
      let num1 = 0;
      num1 = Number(num);
      if (isNaN(num1)) {
        return '';
      } else {
        if (num1 >= str) {
          return str - 0.01;
        } else {
          let value = num1;
          value = value.toString();
          if (value) {
            let ind = value.toString().indexOf('.');
            if (ind < 0) {
              return value + '.00';
            } else {
              if (value.length - 1 - ind > 2) {
                return value.substr(0, ind + 3);
              } else if (value.length - 1 - ind < 2) {
                let index = 2 - (value.length - 1 - ind);
                for (let i = 0; i < index; i++) {
                  value = value + '0';
                }
                return value;
              } else {
                return value;
              }
            }
          }
        }
      }
    },
    //点击导入组件
    clickImport() {
      this.dialogVisible = true;
      setTimeout(() => {
        this.$refs.MtrbImport.initData();
      }, 300);
    },
    // 关闭弹框
    childChanel() {
      this.dialogVisible = false;
    },
    // 确认传值
    childConfirm(value) {
      let val = JSON.parse(JSON.stringify(value));
      val = val.filter(item => this.modcForm.modc_mtrb_list.every(item1 => item.scon_mtrb_id !== item1.scon_mtrb_id));
      val.forEach(item => {
        item.prod_mtrb_price = new BigNumber(item.prod_mtrb_price).toFixed(4);
        item.mtrb_num = new BigNumber(item.scon_mtrb_num).toFixed(0);
        item.supp_bid = item.main_supp_id;
        item.supp_babbr = item.main_supp_abbr;
        item.modc_total = new BigNumber(item.mtrb_num).times(item.prod_mtrb_price).toFixed(4);
        item.fact_modc_total = item.modc_total;
        item.modc_mtrb_price = item.prod_mtrb_price;
        if (item.mtrb_pctype) {
          this.rateList
            .filter(({ param1 }) => param1 === item.mtrb_pctype)
            .forEach(subItem => {
              let num = Number(item.mtrb_num);
              if (num >= Number(subItem.param3) && num < Number(subItem.param2)) {
                item.mtrb_bnum = new BigNumber(num).times(subItem.param4).div(100).toFixed(0, 0);
              }
            });
          item.mtrb_num_total = new BigNumber(item.mtrb_num).plus(item.mtrb_bnum || 0).toNumber();
        }
      });
      this.modcForm.modc_mtrb_list = this.modcForm.modc_mtrb_list.concat(val);
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_total {
  font-size: 24px;
  text-align: right;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}

.vd_color {
  color: #c70e0e;
}
</style>
